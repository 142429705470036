import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { getOwnerDetails } from '@/api'

const useStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    padding: '1vh 0',
    fontSize: '1.8vh',
    '&>div': {
      width: '35%',
      '@media (max-width: 1280px)': {
        width: '37%',
      },
    },
  },
  smallBox: {
    display: 'flex',
  },
}))

interface DailyChargesTableProps {
  idObj: any
  idStr: any
  idNum: any
  childNum: any
}

const OwnerBox = (props: DailyChargesTableProps) => {
  const { idObj, idStr, idNum, childNum } = props
  const { classes } = useStyles()
  const [nameStr, setNameStr] = useState<any>('')

  useEffect(() => {
    if (idNum) {
      getOwnerDetails(idNum)
        .then((res: any) => {
          setNameStr(res?.name)
        })
        .catch(console.error)
    } else {
      setNameStr('')
    }
  }, [idNum])

  useEffect(() => {
    if (childNum) {
      setNameStr('')
    }
  }, [childNum])

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.smallBox}>
          <div>业主地址：</div>
          <div>{`${idStr}${idObj?.num}`}</div>
        </div>
        <div className={classes.smallBox}>
          <div>业主名称：</div>
          <div>{nameStr}</div>
        </div>
      </div>
      <div>
        <div className={classes.smallBox}>
          <div>房屋性质：</div>
          <div>{idObj?.nature}</div>
        </div>
        <div className={classes.smallBox}>
          <div>建筑面积：</div>
          <div>{idObj?.area}</div>
        </div>
      </div>
    </div>
  )
}

export default OwnerBox
