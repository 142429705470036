import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import BuildCollapseBox from '@/components/adminBox/dailyCharges/buildCollapseBox'

const useStyles = makeStyles()(() => ({
  buildDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    padding: '3px 0',
    '&>div': {
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    '&>div>div:nth-of-type(1)': {
      display: 'flex',
      position: 'relative',
      top: 1,
      marginRight: 5,
    },
  },
}))

interface CollapseBoxProps {
  object: any
  getIdNum: (num: any) => void
  idNum: any
  getIdStr: (str: any) => void
  getIdObj: (obj: any) => void
}

const BuildContentBox = (props: CollapseBoxProps) => {
  const { classes } = useStyles()
  const { object, getIdNum, idNum, getIdStr, getIdObj } = props
  const [openBoo, setOpenBoo] = useState<any>(false)

  useEffect(() => {
    if (object) {
      setOpenBoo(object.open)
    }
  }, [object])

  return (
    <React.Fragment>
      <div className={classes.buildDiv}>
        <div
          onClick={() => {
            setOpenBoo(!openBoo)
          }}
        >
          <div>{!openBoo ? <AddCircleOutlineIcon /> : <RemoveCircleOutlineIcon />}</div>
          <div>
            {object.address1}
            {object.address2}
            {object.address3}
          </div>
        </div>
      </div>
      <BuildCollapseBox
        openBoo={openBoo}
        object={object}
        getIdNum={getIdNum}
        idNum={idNum}
        getIdStr={getIdStr}
        getIdObj={getIdObj}
      />
    </React.Fragment>
  )
}

export default BuildContentBox
