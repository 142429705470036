import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import LoadingButton from '@mui/lab/LoadingButton'
import TextField from '@mui/material/TextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import dayjs from 'dayjs'
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import moment from 'moment'
import {
  setAllinpay,
  setBillAudit,
  setCollection,
  setDuplicatePlateNumber,
  setInvalidCarInvoice,
  setOwnerBill,
  setProject,
  setSynchronizeVehicles,
  setSyncProjects,
  setSyncTickets,
} from '@/api'
import { FormControl, MenuItem, Select } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import NavigationImg from '@/image/navigationImg.png'

const useStyles = makeStyles()(() => ({
  btn: {
    position: 'relative',
    background: '#214162',
    color: '#fff',
    fontSize: '2vh',
    padding: '2px 30px',
    '&:hover': {
      background: '#214162',
      color: '#fff',
    },
    '&>span>span': {
      width: '3vh !important',
      height: '3vh !important',
      color: '#999',
    },
  },
  content: {
    height: '25vh',
    marginBottom: 15,
    marginRight: 15,
    borderRadius: 5,
    boxShadow: '-1px 1px 15px -4px #000',
    fontSize: '1.5vh',
    '@media (max-width: 1280px)': {
      marginRight: 0,
    },
  },
  cardTitle: {
    fontSize: '2.5vh',
    background: '#15283c',
    color: '#fff',
    boxShadow: '0px 4px 13px -5px #000',
    padding: 10,
    borderRadius: '5px 5px 0 0',
    height: '5.5vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `url(${NavigationImg})`,
  },
  cardBox: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 'calc( 100% - 5.5vh )',
    '&>div': {
      width: '100%',
      '&:last-child': {
        textAlign: 'end',
      },
    },
  },
  iptTimeBox: {
    display: 'flex',
    alignItems: 'center',
    color: '#000',
    fontSize: '2vh',
    '&>div:nth-of-type(1)': {
      marginRight: 5,
    },
    '&>div:nth-of-type(2)': {
      flex: 1,
      '&>div': {
        height: '100%',
        width: '100%',
        padding: 0,
        '&>div': {
          height: '100%',
          minWidth: 'auto !important',
          '&>div': {
            height: '100%',
            border: '2px solid #000 !important',
            '&>input': {
              height: '100%',
              padding: '7px 10px',
              fontSize: '2vh',
            },
            '&>fieldset': {
              border: '0 !important',
              top: 0,
              height: '100%',
            },
          },
        },
      },
    },
  },
  iptBox: {
    display: 'flex',
    alignItems: 'center',
    color: '#000',
    fontSize: '2vh',
    '&>div:nth-of-type(1)': {
      marginRight: 5,
    },
    '&>div:nth-of-type(2)': {
      flex: 1,
      display: 'flex',
    },
  },
  iptModal: {
    height: '100%',
    width: '100%',
    '&>div': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '2vh',
      background: 'transparent',
      padding: '5px 10px',
      height: '100%',
      '&>input': {
        padding: 0,
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
      '&>div': {
        padding: 0,
      },
    },
    '&:hover::before': {
      borderBottom: '2px solid #000 !important',
    },
  },
  checkBox: {
    margin: 0,
    '&>span:nth-of-type(1)': {
      padding: 0,
      paddingRight: 5,
      '&>svg': {
        width: '3vh',
        height: '3vh',
        color: '#214162',
      },
    },
    '&>span:nth-of-type(2)': {
      color: '#000',
      fontSize: '1.8vh',
      lineHeight: 1.15,
    },
  },
}))

interface SynchronizeBoxProps {
  dataObject: any
  arrParkingLotList: any
  dateObj: any
}

const SynchronizeBox = (props: SynchronizeBoxProps) => {
  const { dataObject, arrParkingLotList, dateObj } = props
  const { classes } = useStyles()
  const [selectValue, setSelectValue] = useState('全部')
  const [checkBoo, setCheckBoo] = useState<any>(false)
  const [textValue, setTextValue] = useState<any>(moment.utc(new Date(new Date())).format('YYYY'))
  const [projectIdValue, setProjectIdValue] = useState<any>(dateObj?.id)
  const [dateValue, setDateValue] = useState<any>([
    moment.utc(new Date(new Date())).format('YYYYMM'),
    moment.utc(new Date(new Date())).format('YYYYMM'),
  ])
  const [dayValue, setDayValue] = useState(moment.utc(new Date(new Date())).format('YYYY-MM-DD'))
  const [dateValue1, setDateValue1] = useState<any>([
    moment.utc(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD'),
    moment.utc(new Date(new Date())).format('YYYY-MM-DD'),
  ])
  const [synchronizeBoo, setSynchronizeBoo] = useState<any>(false)

  const getIptBox = (key: any) => {
    let newDiv
    switch (key) {
      case 'year':
        newDiv = (
          <TextField
            className={classes.iptModal}
            value={textValue}
            onChange={(event: any) => {
              setTextValue(event.target.value)
            }}
            id="outlined-basic"
            variant="outlined"
          />
        )
        break
      case 'month':
        newDiv = (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['SingleInputDateRangeField']}>
              <SingleInputDateRangeField
                disableFuture
                format="YYYY/MM"
                value={[dayjs(dateValue[0]), dayjs(dateValue[1])]}
                onChange={(e: any) => {
                  setDateValue([dayjs(e[0]).format('YYYYMM'), dayjs(e[1]).format('YYYYMM')])
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        )
        break
      case 'day':
        newDiv = (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['SingleInputDateRangeField']}>
              <DateRangePicker
                disableFuture
                format="YYYY/MM/DD"
                value={[dayjs(dateValue1[0]), dayjs(dateValue1[1])]}
                onChange={(e: any) => {
                  const dateOne = e[0] && e[0] !== null ? dayjs(e[0]).format('YYYY-MM-DD') : ''
                  const dateTwo = e[1] && e[1] !== null ? dayjs(e[1]).format('YYYY-MM-DD') : ''
                  setDateValue1([dateOne, dateTwo])
                }}
                slots={{ field: SingleInputDateRangeField }}
              />
            </DemoContainer>
          </LocalizationProvider>
        )
        break
      case 'project':
        newDiv = (
          <FormControl className={classes.iptModal} fullWidth>
            <Select
              labelId="demo-simple-select-label"
              value={arrParkingLotList?.length > 0 ? selectValue : ''}
              onChange={(event: any) => {
                setSelectValue(event.target.value)
              }}
            >
              <MenuItem value="全部">全部</MenuItem>
              {arrParkingLotList?.map((item: any, i: number) => {
                return (
                  <MenuItem key={i} value={item.id}>
                    {`${item.name}-${item.id}`}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )
        break
      case 'projectId':
        newDiv = (
          <FormControl className={classes.iptModal} fullWidth>
            <Select
              labelId="demo-simple-select-label"
              value={arrParkingLotList?.length > 0 ? projectIdValue : ''}
              onChange={(event: any) => {
                setProjectIdValue(event.target.value)
              }}
            >
              {arrParkingLotList?.map((item: any, i: number) => {
                return (
                  <MenuItem key={i} value={item.id}>
                    {`${item.name}-${item.id}`}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )
        break
      case 'date':
        newDiv = (
          <TextField
            id="date-start"
            name="dateStart"
            type="date"
            value={dayValue}
            className={classes.iptModal}
            onChange={(event: any) => {
              setDayValue(event.target.value)
            }}
          />
        )
        break
      case 'checkbox':
        newDiv = (
          <FormGroup>
            <FormControlLabel
              className={classes.checkBox}
              control={
                <Checkbox
                  checked={checkBoo}
                  onChange={() => {
                    setCheckBoo(!checkBoo)
                  }}
                  color="default"
                />
              }
              label="物业管理处"
            />
          </FormGroup>
        )
        break
      default:
        newDiv = (
          <TextField
            className={classes.iptModal}
            value={textValue}
            onChange={(event: any) => {
              setTextValue(event.target.value)
            }}
            id="outlined-basic"
            variant="outlined"
          />
        )
        break
    }

    return newDiv
  }

  return (
    <div key={dataObject?.id} className={classes.content}>
      <div className={classes.cardTitle}>{dataObject?.name}</div>
      <div className={classes.cardBox}>
        {dataObject?.iptName ? (
          dataObject?.iptName?.map((item: any, i: any) => {
            return (
              <div
                key={i}
                className={
                  dataObject?.id === 3 || dataObject?.id === 4 || dataObject?.id === 7
                    ? classes.iptTimeBox
                    : classes.iptBox
                }
              >
                <div>{item}</div>
                <div>{getIptBox(dataObject?.iptType[i])}</div>
              </div>
            )
          })
        ) : (
          <div />
        )}
        <div>
          <LoadingButton
            loading={synchronizeBoo}
            onClick={() => {
              switch (dataObject?.id) {
                case 1:
                  setSynchronizeBoo(true)
                  setCollection(dayValue, checkBoo)
                    .then((res: any) => {
                      if (res?.message === 'Ok' || res?.message === 'success') {
                        setSynchronizeBoo(false)
                      }
                    })
                    .catch(console.error)
                  break
                case 2:
                  setSynchronizeBoo(true)
                  setBillAudit(dayValue)
                    .then((res: any) => {
                      if (res?.message === 'success' || res?.message === 'Ok') {
                        setSynchronizeBoo(false)
                      }
                    })
                    .catch(console.error)
                  break
                case 3:
                  setSynchronizeBoo(true)
                  setOwnerBill(`${dateValue[0]}-${dateValue[1]}`, '', '')
                    .then((res: any) => {
                      if (res?.message === 'success' || res?.message === 'Ok') {
                        setSynchronizeBoo(false)
                      }
                    })
                    .catch(console.error)
                  break
                case 4:
                  setSynchronizeBoo(true)
                  setOwnerBill('', `${dateValue1[0]}~${dateValue1[1]}`, '')
                    .then((res: any) => {
                      if (res?.message === 'success' || res?.message === 'Ok') {
                        setSynchronizeBoo(false)
                      }
                    })
                    .catch(console.error)
                  break
                case 5:
                  setSynchronizeBoo(true)
                  setProject(textValue, selectValue === '全部' ? '' : selectValue, checkBoo)
                    .then((res: any) => {
                      if (res?.message === 'Ok' || res?.message === 'success') {
                        setSynchronizeBoo(false)
                      }
                    })
                    .catch(console.error)
                  break
                case 6:
                  setSynchronizeBoo(true)
                  setSynchronizeVehicles(projectIdValue)
                    .then((res: any) => {
                      if (res?.message === 'success' || res?.message === 'Ok') {
                        setSynchronizeBoo(false)
                      }
                    })
                    .catch(console.error)
                  break
                case 7:
                  setSynchronizeBoo(true)
                  setSyncTickets(`${dateValue1[0]}~${dateValue1[1]}`)
                    .then((res: any) => {
                      if (res?.message === 'success' || res?.message === 'Ok') {
                        setSynchronizeBoo(false)
                      }
                    })
                    .catch(console.error)
                  break
                case 8:
                  setSynchronizeBoo(true)
                  setSyncProjects()
                    .then((res: any) => {
                      if (res?.message === 'success' || res?.message === 'Ok') {
                        setSynchronizeBoo(false)
                      }
                    })
                    .catch(console.error)
                  break
                case 9:
                  setSynchronizeBoo(true)
                  setAllinpay(dayValue)
                    .then((res: any) => {
                      if (res?.message === 'success' || res?.message === 'Ok') {
                        setSynchronizeBoo(false)
                      }
                    })
                    .catch(console.error)
                  break
                case 10:
                  setSynchronizeBoo(true)
                  setInvalidCarInvoice(projectIdValue, dayValue)
                    .then((res: any) => {
                      if (res?.message === 'success' || res?.message === 'Ok') {
                        setSynchronizeBoo(false)
                      }
                    })
                    .catch(console.error)
                  break
                case 11:
                  setSynchronizeBoo(true)
                  setDuplicatePlateNumber()
                    .then((res: any) => {
                      if (res?.message === 'success' || res?.message === 'Ok') {
                        setSynchronizeBoo(false)
                      }
                    })
                    .catch(console.error)
                  break
                default:
                  break
              }
            }}
            variant="contained"
            className={classes.btn}
            disabled={synchronizeBoo}
          >
            同步
          </LoadingButton>
        </div>
      </div>
    </div>
  )
}

export default SynchronizeBox
