import React, { useEffect, useState } from 'react'
import { makeStyles, withStyles } from 'tss-react/mui'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import Pagination from '@mui/material/Pagination'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import { exportExcel, getCarList, getCarType, getProjectList, getQueryParamsObj } from '@/api'
import moment from 'moment'
import { FormControl, MenuItem, Select } from '@mui/material'
import TextField from '@mui/material/TextField'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import { useNavigate } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { styled } from '@mui/material/styles'
import ModalBox from '@/components/adminBox/carListBox/ModalBox'
import CarListBtnBox from '@/components/adminBox/carListBox/btnBox'
import classNames from 'classnames'
import NavigationImg from '@/image/navigationImg.png'

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: '1.4vh',
  },
}))

const StyledTableCell = withStyles(TableCell, (theme) => ({
  head: {
    color: theme.palette.common.black,
    padding: '1.5vh 0',
    border: 0,
    fontSize: '2.2vh',
    fontWeight: 600,
    lineHeight: 1.15,
    '@media (max-width: 1280px)': {
      fontSize: '1.5vh',
      padding: '1.3vh 0',
    },
  },
  body: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    padding: '1vh 0',
    fontSize: '1.8vh',
    lineHeight: 1.15,
    borderBottom: '1px solid rgba(238,238,238,0.3)',
    '@media (max-width: 1280px)': {
      fontSize: '1.4vh',
    },
  },
}))

const StyledTableRow = withStyles(TableRow, () => ({
  root: {
    // borderRadius: 5,
    // backgroundImage: `url(${NavigationImg})`,
    // backgroundColor: '#214162',
  },
}))

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    height: '100%',
    padding: 15,
  },
  thBox: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    lineHeight: '34px',
    '@media (max-width: 1280px)': {
      lineHeight: '18px',
    },
  },
  fillButton: {
    color: '#fff',
    padding: '0.5vh',
    width: '3.5vh',
    height: '3.5vh',
    minWidth: '3.5vh !important',
    '&>svg': {
      width: '100%',
      height: '100%',
    },
  },
  turnPage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  paginationBox: {
    '&>ul>li>.MuiPaginationItem-page.Mui-selected': {
      backgroundColor: '#c4c1c1',
      color: '#333',
    },
    '&>ul>li>button': {
      color: '#999',
      fontSize: 40,
      height: 48,
      minWidth: 48,
      padding: 0,
      '@media (max-width: 1280px)': {
        fontSize: 18,
        height: 26,
        minWidth: 26,
      },
    },
    '&>ul>li>button>svg': {
      fontSize: 45,
      padding: 0,
      '@media (max-width: 1280px)': {
        fontSize: 35,
      },
    },
    '&>ul>li>div': {
      color: '#999',
      fontSize: 40,
      height: 48,
      minWidth: 48,
      padding: 0,
      '@media (max-width: 1280px)': {
        fontSize: 26,
        height: 30,
        minWidth: 30,
      },
    },
  },
  title: {
    padding: '10px 0',
    width: '100%',
    height: '7%',
    display: 'flex',
    justifyContent: 'space-between',
    '&>div:nth-of-type(2)': {
      display: 'flex',
    },
    '@media (max-width: 1280px)': {
      flexDirection: 'column',
      height: '30%',
      justifyContent: 'space-between',
    },
  },
  titleLeftBox: {
    display: 'flex',
    color: '#000',
    height: '100%',
    width: '70%',
    '@media (max-width: 1280px)': {
      flexDirection: 'column',
      height: '80%',
    },
  },
  selectBigBox: {
    width: '24%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
    '@media (max-width: 1280px)': {
      width: '100%',
      height: '20%',
      marginRight: 0,
      marginBottom: 10,
    },
  },
  titleText: {
    width: '8vh',
    fontSize: '2.5vh',
    fontWeight: 600,
    '@media (max-width: 1280px)': {
      width: '12vh',
    },
  },
  iptModal: {
    height: '100%',
    width: '100%',
    '&>div': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '2vh',
      background: 'transparent',
      padding: '6px 3.2vh 6px 10px',
      height: '100%',
      '&>input': {
        padding: 0,
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
      '&>div': {
        padding: '0 !important',
      },
    },
    '&>input': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '2vh',
      background: 'transparent',
      padding: '6px 10px',
      '&>input': {
        padding: 0,
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
    },
    '&:hover::before': {
      borderBottom: '2px solid #000 !important',
    },
  },
  iptModalDelete: {
    '&>div>input': {
      width: '88% !important',
    },
  },
  selectBox: {
    width: '70%',
    height: '100%',
    position: 'relative',
    '@media (max-width: 1280px)': {
      width: '22vh',
    },
  },
  tableBox: {
    height: 'calc( 93% - 48px - 10px )',
    width: '100%',
    overflowY: 'auto',
    '@media (max-width: 1280px)': {
      height: 'calc( 70% - 30px)',
      '&>table': {
        minWidth: 1000,
      },
    },
  },
  btn: {
    position: 'relative',
    marginLeft: 15,
    background: '#214162',
    color: '#fff',
    fontSize: '2.5vh',
    padding: '2px 30px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      background: '#214162',
      color: '#fff',
    },
    '&>span': {
      position: 'absolute',
      width: '3vh !important',
      height: '3vh !important',
      color: '#999',
    },
    '@media (max-width: 1280px)': {
      fontSize: '2vh',
    },
  },
  modalBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalSmallBox: {
    width: '60%',
    height: '10%',
    background: '#fff',
    padding: 10,
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalOneBox: {
    display: 'flex',
    flexDirection: 'column',
    background: '#fff',
    borderRadius: 5,
  },
  modalTitle: {
    fontSize: '3vh',
    padding: '5px 0',
    background: '#15283c',
    backgroundImage: `url(${NavigationImg})`,
    color: '#fff',
    textAlign: 'center',
    boxShadow: '0px 4px 13px -5px #000',
    zIndex: 1,
    borderRadius: '5px 5px 0 0',
  },
  modalSmallBox1: {
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '&>div': {
      display: 'flex',
      '&>div': {
        width: '14vh',
        fontSize: '2.5vh',
        fontWeight: 600,
      },
    },
  },
  iconBox: {
    width: '3vh',
    height: '3vh',
    position: 'absolute',
    right: 4,
    top: 'calc( 50% - 1.5vh )',
    color: '#aaa',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  thBoxHover: {
    color: '#4169e1',
    borderBottom: '2px solid #4169e1',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

interface CarListBoxProps {
  divWidth: any
  urlName: any
}

const CarListBox = (props: CarListBoxProps) => {
  const { divWidth, urlName } = props
  const { classes } = useStyles()
  const [open, setOpen] = useState<any>(false)
  const [excelBoo, setExcelBoo] = useState<any>(false)
  const [open1, setOpen1] = useState<any>(false)
  const [open2, setOpen2] = useState<any>(false)
  const [ownerIdStr, setOwnerIdStr] = useState<any>('')
  const [carIdStr, setCarIdStr] = useState<any>('')
  const [arrList, setArrList] = useState<any>([])
  const [carTypeList, setCarTypeList] = useState<any>([])
  const [addressObj, setAddressObj] = useState<any>('')
  const [selectValue, setSelectValue] = useState('')
  const [typeValue, setTypeValue] = useState('全部')
  const [plateString, setPlateString] = useState<any>('')
  const [addressString, setAddressString] = useState<any>('')
  const [arrParkingLotList, setArrParkingLotList] = useState<any>([])
  const [page, setPage] = useState<any>(1)
  const urlObj = getQueryParamsObj(window.location?.search)
  const navigate = useNavigate()

  const getParkingLotArr = () => {
    getProjectList()
      .then((res: any) => {
        setArrParkingLotList(res)
      })
      .catch(console.error)
  }

  const getCarTypeList = () => {
    getCarType(urlObj?.id)
      ?.then((res: any) => {
        setCarTypeList(res)
      })
      .catch(console.error)
  }

  const typeArr = [
    { key: '0', name: '全部' },
    { key: '1', name: '月租车' },
    { key: '2', name: '亲情车' },
    { key: '3', name: '免费车' },
    { key: '4', name: '公务车' },
    { key: '5', name: '月租一辆' },
    { key: '6', name: '月租二辆' },
    { key: '7', name: '月租三辆' },
    { key: '8', name: '业主副车' },
    { key: '9', name: '租户一辆' },
    { key: '10', name: '租户二辆' },
    { key: '11', name: '单月月租车' },
    { key: '12', name: '月租临停车' },
    { key: '13', name: '过期月租车' },
  ]

  const headerArr = [
    {
      key: 0,
      name: '区域/车位号',
      width: '20%',
    },
    {
      key: 1,
      name: '类型',
      width: '7%',
    },
    {
      key: 2,
      name: '业主',
      width: '7%',
    },
    {
      key: 3,
      name: '地址',
      width: '20%',
    },
    {
      key: 4,
      name: '收费标准',
      width: '7%',
    },

    {
      key: 5,
      name: '车牌号',
      width: '9%',
    },
    {
      key: 6,
      name: '起始日期',
      width: '8%',
    },
    {
      key: 7,
      name: '终止日期',
      width: '8%',
    },
    {
      key: 8,
      name: '缴费至',
      width: '8%',
    },
    {
      key: 9,
      name: '操作',
      width: '6%',
    },
  ]

  const setNewOwnerIdStr = () => {
    setOwnerIdStr('')
    setCarIdStr('')
  }

  const getData = (id: any, size: number) => {
    getCarList(
      id,
      page - 1,
      size,
      plateString?.replace(/\s+/g, '') || decodeURI(urlObj?.result),
      typeValue,
      addressString?.replace(/\s+/g, '') || decodeURI(urlObj?.address),
    )
      .then((res: any) => {
        setArrList(res)
      })
      .catch(console.error)
  }
  const exportObj = (res: any) => {
    let arrObj: any = {}
    arrObj = {
      '!cols': [],
      '!fullref': `A1:J${(res?.data?.length || 0) + 1}`,
      '!ref': `A1:J${(res?.data?.length || 0) + 1}`,
      '!rows': [],
      A1: { t: 's', v: '区域' },
      B1: { t: 's', v: '车位号' },
      C1: { t: 's', v: '类型' },
      D1: { t: 's', v: '姓名' },
      E1: { t: 's', v: '地址' },
      F1: { t: 's', v: '收费标准' },
      G1: { t: 's', v: '车牌号' },
      H1: { t: 's', v: '起始日期' },
      I1: { t: 's', v: '终止日期' },
      J1: { t: 's', v: '缴费至' },
    }
    res?.data?.forEach((item: any, i: number) => {
      arrObj[`A${i + 2}`] = { t: 's', v: item.garageName || '' }
      arrObj[`B${i + 2}`] = { t: 's', v: item.parkingSpaceNum || '' }
      arrObj[`C${i + 2}`] = { t: 's', v: item.carType || '' }
      arrObj[`D${i + 2}`] = { t: 's', v: item.carPeople || '' }
      arrObj[`E${i + 2}`] = { t: 's', v: item.carAddress || '' }
      arrObj[`F${i + 2}`] = { t: 's', v: item.carCharge || '' }
      arrObj[`G${i + 2}`] = { t: 's', v: item.plateNumber || '' }
      arrObj[`H${i + 2}`] = {
        t: 's',
        v: item?.beginDate !== null ? moment.utc(item?.beginDate).format('YYYY-MM-DD') : '',
      }
      arrObj[`I${i + 2}`] = {
        t: 's',
        v:
          item?.endDate !== null
            ? moment.utc(item?.endDate).format('YYYY-MM-DD')
            : item?.expirationDate !== null
            ? moment.utc(item?.expirationDate).format('YYYY-MM-DD')
            : '',
      }
      arrObj[`J${i + 2}`] = {
        t: 's',
        v: item?.lastPayTime !== null ? moment.utc(item?.lastPayTime).format('YYYY-MM-DD') : '',
      }
    })
    exportExcel(
      arrObj,
      `${arrParkingLotList.filter((res: any) => res.id === selectValue)[0]?.name}车辆管理-${moment
        .utc(new Date())
        .format('YYYY-MM-DD')}.xlsx`,
    )
    setExcelBoo(false)
  }

  const getExportData = () => {
    getCarList(
      selectValue || urlObj?.id,
      page - 1,
      999999,
      plateString?.replace(/\s+/g, '') || decodeURI(urlObj?.result),
      typeValue,
      addressString?.replace(/\s+/g, '') || decodeURI(urlObj?.address),
    )
      .then((res: any) => {
        exportObj(res)
      })
      .catch(console.error)
  }

  const getList = () => {
    const tableBox = document.getElementById('tableBox')
    if (tableBox?.clientHeight) {
      divWidth <= 1280
        ? getData(selectValue || urlObj?.id, Math.trunc(tableBox?.clientHeight / 43) - 1)
        : getData(selectValue || urlObj?.id, Math.trunc(tableBox?.clientHeight / 56) - 1)
    } else {
      getData(selectValue || urlObj?.id, 12)
    }
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
  }
  const handleClose1 = () => {
    setOpen1(false)
  }

  const handleClose2 = () => {
    setOwnerIdStr('')
    setCarIdStr('')
    setOpen2(false)
  }

  useEffect(() => {
    if (arrList?.data?.length > 0) {
      getList()
    }
  }, [page])

  useEffect(() => {
    getParkingLotArr()
  }, [])

  useEffect(() => {
    getCarTypeList()
  }, [urlObj?.id])

  useEffect(() => {
    if (urlObj?.id) {
      setSelectValue(urlObj?.id || '')
    }
    if (urlObj?.result) {
      setPlateString(decodeURI(urlObj?.result))
    }
    if (urlObj?.address) {
      setAddressString(decodeURI(urlObj?.address))
    }
    getList()
  }, [urlObj?.result, urlObj?.id, urlObj?.address])

  const enterList = () => {
    let newStr = `?id=${selectValue}`
    const oldObj = urlObj
    if (plateString?.replace(/\s+/g, '') !== '') {
      newStr += `&result=${plateString?.replace(/\s+/g, '')}`
    }
    if (addressString?.replace(/\s+/g, '') !== '') {
      newStr += `&address=${addressString?.replace(/\s+/g, '')}`
    }
    if (
      (decodeURI(oldObj?.result) === plateString?.replace(/\s+/g, '') ||
        (`${decodeURI(oldObj?.result)}` === 'undefined' && plateString?.replace(/\s+/g, '') === '')) &&
      (oldObj?.id === selectValue || (`${oldObj?.id}` === 'undefined' && selectValue === '')) &&
      (decodeURI(oldObj?.address) === addressString?.replace(/\s+/g, '') ||
        (`${decodeURI(oldObj?.address)}` === 'undefined' && addressString === ''))
    ) {
      getList()
    }
    navigate(newStr)
    page !== 1 && setPage(1)
  }

  const EnterKey = new Set(['Enter'])
  const HandleKeyDown = ({ key }: KeyboardEvent) => {
    EnterKey.has(key) && enterList()
  }

  useEffect(() => {
    window.addEventListener('keydown', HandleKeyDown)
    return () => {
      window.removeEventListener('keydown', HandleKeyDown)
    }
  })

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <div className={classes.titleLeftBox}>
          {!urlName.includes('management') && (
            <div className={classes.selectBigBox}>
              <div className={classes.titleText}>小区：</div>
              <div className={classes.selectBox}>
                <FormControl className={classes.iptModal} fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    value={arrParkingLotList?.length > 0 ? selectValue : ''}
                    onChange={(event: any) => {
                      setSelectValue(event.target.value)
                    }}
                  >
                    {arrParkingLotList?.map((item: any, i: number) => {
                      return (
                        <MenuItem key={i} value={item.id}>
                          {`${item.name}-${item.id}`}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
          )}
          <div className={classes.selectBigBox}>
            <div className={classes.titleText}>类型：</div>
            <div className={classes.selectBox}>
              <FormControl className={classes.iptModal} fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  value={typeValue}
                  onChange={(event: any) => {
                    setTypeValue(event.target.value)
                  }}
                >
                  {typeArr?.map((item: any, i: number) => {
                    return (
                      <MenuItem key={i} value={item.name}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={classes.selectBigBox}>
            <div style={{ width: '12vh' }} className={classes.titleText}>
              车牌号：
            </div>
            <div className={classes.selectBox}>
              <TextField
                className={classNames([classes.iptModal, classes.iptModalDelete])}
                value={plateString}
                onChange={(event: any) => {
                  setPlateString(event.target.value)
                }}
                id="outlined-basic"
                variant="outlined"
              />
              {plateString !== '' && (
                <HighlightOffIcon
                  className={classes.iconBox}
                  onClick={() => {
                    setPlateString('')
                  }}
                />
              )}
            </div>
          </div>
          <div className={classes.selectBigBox}>
            <div className={classes.titleText}>地址：</div>
            <div className={classes.selectBox}>
              <TextField
                className={classNames([classes.iptModal, classes.iptModalDelete])}
                value={addressString}
                onChange={(event: any) => {
                  setAddressString(event.target.value)
                }}
                id="outlined-basic"
                variant="outlined"
              />
              {addressString !== '' && (
                <HighlightOffIcon
                  className={classes.iconBox}
                  onClick={() => {
                    setAddressString('')
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div>
          <Button
            onClick={() => {
              const oldObj = urlObj
              let newStr = `?id=${selectValue}`
              if (plateString?.replace(/\s+/g, '') !== '') {
                newStr += `&result=${plateString?.replace(/\s+/g, '')}`
              }
              if (addressString?.replace(/\s+/g, '') !== '') {
                newStr += `&address=${addressString?.replace(/\s+/g, '')}`
              }
              if (
                (decodeURI(oldObj?.result) === plateString?.replace(/\s+/g, '') ||
                  (`${decodeURI(oldObj?.result)}` === 'undefined' && plateString?.replace(/\s+/g, '') === '')) &&
                (oldObj?.id === selectValue || (`${oldObj?.id}` === 'undefined' && selectValue === '')) &&
                (decodeURI(oldObj?.address) === addressString?.replace(/\s+/g, '') ||
                  (`${decodeURI(oldObj?.address)}` === 'undefined' && addressString?.replace(/\s+/g, '') === ''))
              ) {
                getList()
              }
              navigate(newStr)
              page !== 1 && setPage(1)
            }}
            variant="contained"
            className={classes.btn}
          >
            查询
          </Button>
          <Button
            onClick={() => {
              setExcelBoo(true)
              getExportData()
            }}
            disabled={excelBoo}
            variant="contained"
            className={classes.btn}
          >
            导出
            {excelBoo && <CircularProgress />}
          </Button>
          <Button
            onClick={() => {
              setOpen1(true)
            }}
            variant="contained"
            className={classes.btn}
          >
            统计
          </Button>
        </div>
      </div>
      <div id="tableBox" className={classes.tableBox}>
        <Table stickyHeader aria-label="customized table">
          <TableHead>
            <StyledTableRow>
              {headerArr?.map((item: any) => {
                return (
                  <StyledTableCell style={{ width: item.width }} key={item.key} align="left">
                    {item.name}
                  </StyledTableCell>
                )
              })}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {arrList?.data?.map((item: any, i: number) => {
              return (
                <TableRow
                  style={{ background: addressObj?.id === item.id ? '#ccc' : 'transparent' }}
                  onClick={() => {
                    if (divWidth <= 1280) {
                      handleOpen()
                      setAddressObj({ id: item.id, name: item?.carAddress })
                    }
                  }}
                  key={i}
                >
                  <StyledTableCell align="left">
                    {divWidth <= 1280 ? (
                      <div className={classes.thBox}>{`${item?.garageName}-${
                        `${item?.parkingSpaceNum}` === 'null' ? '' : item?.parkingSpaceNum
                      }`}</div>
                    ) : (
                      <BootstrapTooltip title={item.garageName} placement="top">
                        <div className={classes.thBox}>{`${item?.garageName}-${
                          `${item?.parkingSpaceNum}` === 'null' ? '' : item?.parkingSpaceNum
                        }`}</div>
                      </BootstrapTooltip>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div className={classes.thBox}>{item?.carType}</div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div style={{ width: divWidth <= 1030 ? 68 : '6.5vw' }} className={classes.thBox}>
                      {item.ownerId ? (
                        <span
                          className={classes.thBoxHover}
                          onClick={() => {
                            navigate(`${urlName}31?ownerId=${item.ownerId}`)
                          }}
                        >
                          {item?.carPeople}
                        </span>
                      ) : (
                        item?.carPeople
                      )}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {divWidth <= 1030 ? (
                      <div style={{ width: 200 }} className={classes.thBox}>
                        {item?.carAddress}
                      </div>
                    ) : (
                      <BootstrapTooltip title={item?.carAddress} placement="top">
                        <div style={{ width: '19.4vw' }} className={classes.thBox}>
                          {item?.carAddress}
                        </div>
                      </BootstrapTooltip>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div className={classes.thBox}>{item?.carCharge}</div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <BootstrapTooltip title={`ID：${item?.id}  业主ID：${item?.ownerId}`} placement="top">
                      <div className={classes.thBox}>{item?.plateNumber}</div>
                    </BootstrapTooltip>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div className={classes.thBox}>
                      {item?.beginDate !== null ? moment.utc(item?.beginDate).format('YYYY-MM-DD') : ''}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div className={classes.thBox}>
                      {item?.endDate !== null
                        ? moment.utc(item?.endDate).format('YYYY-MM-DD')
                        : item?.expirationDate !== null
                        ? moment.utc(item?.expirationDate).format('YYYY-MM-DD')
                        : ''}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div className={classes.thBox}>
                      <span
                        className={classes.thBoxHover}
                        onClick={(e: any) => {
                          e.stopPropagation()
                          setOpen2(true)
                          setOwnerIdStr(item?.ownerId)
                          setCarIdStr(item?.id)
                        }}
                      >
                        {item?.lastPayTime !== null ? moment.utc(item?.lastPayTime).format('YYYY-MM-DD') : ''}
                      </span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div className={classes.thBox}>{item?.ownerId && <CarListBtnBox ownerId={item?.ownerId} />}</div>
                  </StyledTableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
      <div className={classes.turnPage}>
        <Pagination
          className={classes.paginationBox}
          boundaryCount={1}
          page={page}
          onChange={(_e, num: number) => {
            setPage(num)
          }}
          count={arrList?.pages || 0}
          shape="rounded"
        />
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        className={classes.modalBox}
      >
        <div className={classes.modalSmallBox}>{addressObj?.name}</div>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open1}
        onClose={handleClose1}
        closeAfterTransition
        className={classes.modalBox}
      >
        <div className={classes.modalOneBox}>
          <div className={classes.modalTitle}>车辆数量</div>
          <div className={classes.modalSmallBox1}>
            {carTypeList?.map((item: any, i: any) => {
              return (
                <div key={i}>
                  <div className={classes.titleText}>{`${item?.carType}：`}</div>
                  <div style={{ textAlign: 'end' }} className={classes.titleText}>{`${item?.count} 辆`}</div>
                </div>
              )
            })}
          </div>
        </div>
      </Modal>
      <ModalBox
        divWidth={divWidth}
        ownerIdStr={ownerIdStr}
        carIdStr={carIdStr}
        setNewOwnerIdStr={setNewOwnerIdStr}
        open={open2}
        handleClose={handleClose2}
      />
    </div>
  )
}

export default CarListBox
