import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Collapse } from '@mui/material'
import { getHouseList } from '@/api'

const useStyles = makeStyles()(() => ({
  collBox: {
    paddingLeft: 40,
    display: 'flex',
    '&>div:hover': {
      cursor: 'pointer',
    },
  },
  btnBoxBg: {
    background: '#214162',
    color: '#fff',
  },
}))

interface CollapseBoxProps {
  openBoo: boolean
  object: any
  getIdNum: (num: any) => void
  getIdObj: (obj: any) => void
  getIdStr: (str: any) => void
  idNum: any
}

const BuildCollapseBox = (props: CollapseBoxProps) => {
  const { classes } = useStyles()
  const { openBoo, object, idNum, getIdNum, getIdStr, getIdObj } = props
  const [houseArr, setHouseArr] = useState<any>([])

  useEffect(() => {
    if (openBoo) {
      getHouseList(object.projectId, object.id)
        .then((res: any) => {
          setHouseArr(res?.data)
        })
        .catch(console.error)
    }
  }, [openBoo])

  return (
    <Collapse in={openBoo} timeout="auto" unmountOnExit>
      {houseArr?.map((item: any, i: any) => {
        return (
          <div className={classes.collBox} key={i}>
            <div
              className={idNum === item.ownerId ? classes.btnBoxBg : ''}
              onClick={() => {
                getIdStr(`${object.address1}${object.address2}${object.address3}`)
                getIdNum(item.ownerId)
                getIdObj(item)
              }}
            >
              {item.num}
            </div>
          </div>
        )
      })}
    </Collapse>
  )
}

export default BuildCollapseBox
