import React, { useEffect, useState } from 'react'
import { makeStyles, withStyles } from 'tss-react/mui'
import {
  exportExcel,
  getBuildingList,
  getCommunityList,
  getNewNumber,
  getNewNumberPercentage,
  getOwnerList,
  getProjectList,
  getQueryParamsObj,
} from '@/api'
import { FormControl, MenuItem, Select } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import moment from 'moment/moment'
import LoadingBox from '@/components/baseMap/loadingBox'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import dayjs from 'dayjs'

const StyledTableCell = withStyles(TableCell, (theme) => ({
  head: {
    color: theme.palette.common.black,
    background: '#fff',
    padding: '1.5vh 0',
    border: 0,
    fontSize: '2.2vh',
    fontWeight: 600,
    lineHeight: 1.15,
    '@media (max-width: 1280px)': {
      fontSize: '1.9vh',
    },
  },
  body: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    padding: '1vh 0',
    fontSize: '1.8vh',
    lineHeight: 1.15,
    '@media (max-width: 1280px)': {
      fontSize: '1.6vh',
    },
  },
}))

const StyledTableRow = withStyles(TableRow, () => ({
  root: {
    // backgroundColor: '#31353E',
  },
}))

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    height: '100%',
    padding: 15,
    '@media (max-width: 1280px)': {
      overflow: 'auto',
      paddingTop: 0,
      display: 'flex',
      flexDirection: 'column',
    },
  },
  title: {
    padding: '10px 0',
    width: '100%',
    height: '7%',
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 1280px)': {
      height: 'auto',
      padding: 0,
      paddingBottom: 10,
      flexDirection: 'column',
    },
  },
  titleLeftBox: {
    display: 'flex',
    color: '#000',
    width: '40%',
    '&>div:nth-of-type(1)': {
      marginLeft: 0,
    },
    '&>div:last-child': {
      '@media (max-width: 1280px)': {
        margin: 0,
      },
    },
    '@media (max-width: 1280px)': {
      flexDirection: 'column',
      height: '100%',
      width: '60%',
    },
  },
  selectBigBox: {
    width: '35%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10,
    '@media (max-width: 1280px)': {
      width: '100%',
      height: '26%',
      marginLeft: 0,
      marginBottom: 10,
    },
  },
  titleText: {
    width: '8vh',
    fontSize: '2.5vh',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    '@media (max-width: 1280px)': {
      width: 'auto',
    },
  },
  iptModal: {
    height: '100%',
    '&>div': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '2vh',
      background: 'transparent',
      padding: '6px 10px',
      height: '100%',
      '&>input': {
        padding: 0,
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
      '&>div': {
        padding: 0,
      },
    },
    '&>input': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '2vh',
      background: 'transparent',
      padding: '6px 10px',
      '&>input': {
        padding: 0,
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
    },
    '&:hover::before': {
      borderBottom: '2px solid #000 !important',
    },
  },
  selectBox: {
    width: '75%',
    height: '100%',
    '@media (max-width: 1280px)': {
      width: '22vh',
    },
  },
  iphoneBtnBox: {
    width: '35%',
    textAlign: 'end',
  },
  content: {
    marginBottom: 15,
    borderRadius: 5,
    boxShadow: '-1px 1px 15px -4px #000',
    padding: 10,
    height: '25%',
    color: '#000',
    position: 'relative',
    '@media (max-width: 1280px)': {
      height: 'auto',
      marginTop: 15,
    },
  },
  tableBox: {
    position: 'relative',
    height: 'calc(63% - 15px)',
    overflow: 'auto',
    '@media (max-width: 1280px)': {
      height: 'auto',
      overflow: 'initial',
    },
  },
  breadcrumbBox: {
    height: '5%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&>nav': {
      fontSize: '2.5vh',
      '@media (max-width: 1280px)': {
        fontSize: '2vh',
      },
      '&>ol>li>p': {
        fontSize: '2.5vh',
        '@media (max-width: 1280px)': {
          fontSize: '2vh',
        },
      },
      '&>ol>li>a': {
        color: '#1E90FF',
        textDecoration: 'none',
        borderBottom: '1px solid #1E90FF',
        '&:hover': {
          cursor: 'pointer',
          textDecoration: 'none',
        },
      },
    },
    '@media (max-width: 1280px)': {
      height: 'auto',
      padding: '8px 0',
    },
  },
  btn: {
    background: '#214162',
    color: '#fff',
    fontSize: '2.5vh',
    padding: '2px 30px',
    height: '100%',
    whiteSpace: 'nowrap',
    '&:hover': {
      background: '#214162',
      color: '#fff',
    },
    '@media (max-width: 1280px)': {
      fontSize: '2vh',
    },
  },
  cardBox: {
    padding: 10,
    borderRadius: 5,
    boxShadow: '-1px 1px 15px -4px #000',
    height: '100%',
    overflow: 'auto',
    color: '#000',
  },
  cardBoxWidth1: {
    width: '100%',
  },
  cardBoxWidth2: {
    width: '83%',
  },
  tableBody: {
    border: '1px solid #000',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10,
    fontSize: '1.5vh',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tableBody1: {
    border: '1px solid #000',
    borderTop: 0,
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  detailsBox: {
    marginBottom: 15,
    paddingBottom: 15,
    borderBottom: '1px solid #ccc',
  },
  thBox: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    lineHeight: '34px',
    width: '8.7vw',
    '&>svg': {
      width: '3vh',
      height: '3vh',
    },
    '@media (max-width: 1280px)': {
      lineHeight: '18px',
      width: '16.2vw',
      whiteSpace: 'pre-wrap',
      textOverflow: 'initial',
      wordBreak: 'break-all',
    },
  },
  collapseBox: {
    fontSize: '1.5vh',
    color: '#000',
  },
  thBoxHover: {
    color: '#4169e1',
    borderBottom: '2px solid #4169e1',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  checkBox: {
    margin: 0,
    '&>span:nth-of-type(1)': {
      padding: 0,
      paddingRight: 5,
      '&>svg': {
        color: '#214162',
      },
    },
    '&>span:nth-of-type(2)': {
      color: '#000',
    },
  },
  loadingBox: {
    position: 'absolute',
    top: 30,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
  },
  dateBox: {
    height: '100%',
    width: '75%',
    '&>div': {
      height: '100%',
      padding: 0,
      overflow: 'hidden',
      '&>div': {
        height: '100%',
        minWidth: 'auto !important',
        '&>div': {
          height: '100%',
          '@media (max-width: 1280px)': {
            padding: '9px 14px 9px 0px',
          },
          '&>input': {
            padding: '0 10px',
            fontSize: '1.8vh',
          },
          '&>div': {
            height: '100%',
          },
          '&>fieldset': {
            top: '-4px',
            border: '2px solid #000 !important',
          },
        },
      },
    },
    '@media (max-width: 1280px)': {
      width: '22vh',
    },
  },
}))

interface PropertyDetailsProps {
  divWidth: any
  urlName: any
}

let timer: NodeJS.Timeout
const PropertyDetails = (props: PropertyDetailsProps) => {
  const { divWidth, urlName } = props
  const { classes } = useStyles()
  const navigate = useNavigate()
  const shadowBoo = false
  const [arrList, setArrList] = useState<any>([])
  const [loadingBoo, setLoadingBoo] = useState<any>(true)
  const [checked, setChecked] = useState(false)
  const [stackBoo, setStackBoo] = useState(false)
  const [titleBoo, setTitleBoo] = useState(true)
  const [communityObj, setCommunityObj] = useState<any>('')
  const [typographyValue, setTypographyValue] = useState('')
  const [selectValue, setSelectValue] = useState('')
  const [ytBuildingIdValue, setYtBuildingIdValue] = useState('')
  const [addressValue, setAddressValue] = useState('')
  const [typeValue, setTypeValue] = useState('')
  const [arrExportList, setArrExportList] = useState<any>('')
  const [exportBoo, setExportBoo] = useState<any>(false)
  const [arrParkingLotList, setArrParkingLotList] = useState<any>([])
  const [dateValue, setDateValue] = useState<any>(
    Number(moment.utc(new Date()).format('MM')) < 4
      ? moment.utc(new Date()).subtract(1, 'year').format('YYYY')
      : moment.utc(new Date()).format('YYYY'),
  )
  const propertyCommunity = getQueryParamsObj(window.location?.search)

  const headerArr = [
    {
      key: 1,
      name: '地址',
      width: '10%',
    },
    {
      key: 2,
      name: '当年应收',
      width: '10%',
    },
    {
      key: 3,
      name: '当年预收',
      width: '10%',
    },
    {
      key: 4,
      name: '收当年',
      width: '10%',
    },
    {
      key: 5,
      name: '收上年欠',
      width: '10%',
    },
    {
      key: 6,
      name: '收历年欠',
      width: '10%',
    },
    {
      key: 7,
      name: '当年欠',
      width: '10%',
    },
    {
      key: 8,
      name: '上年欠',
      width: '10%',
    },
    {
      key: 9,
      name: '历年欠',
      width: '10%',
    },
    {
      key: 10,
      name: '上年预收',
      width: '10%',
    },
  ]

  const iphoneHeaderArr = [
    {
      key: 1,
      name: '地址',
      width: '23.2%',
    },
    {
      key: 2,
      name: '收当年',
      width: '19.2%',
    },
    {
      key: 3,
      name: '当年欠',
      width: '19.2%',
    },
    {
      key: 4,
      name: '收历年',
      width: '19.2%',
    },
    {
      key: 5,
      name: '历年欠',
      width: '19.2%',
    },
  ]

  const communityHeaderArr = [
    {
      key: 1,
      name: '',
      width: '25%',
    },
    {
      key: 2,
      name: '指标数',
      width: '25%',
    },
    {
      key: 3,
      name: '完成数',
      width: '25%',
    },
    {
      key: 4,
      name: '完成度',
      width: '25%',
    },
  ]

  const typeObj: any = {
    '5': '管理费',
    '6': '租金',
    '7': '商铺管理费',
    '8': '停车费',
    '17': '管理费',
    '18': '租金',
    '19': '商铺管理费',
    '20': '停车费',
  }

  const getParkingLotArr = () => {
    getProjectList()
      .then((res: any) => {
        setArrParkingLotList(res)
      })
      .catch(console.error)
  }

  const getData = (str: any, accountType: any) => {
    getBuildingList(str, accountType, shadowBoo, dateValue)
      .then((res: any) => {
        setArrList(res?.data)
        setStackBoo(false)
      })
      .catch(console.error)
  }

  const getNewArr = (str: any, accountType: any, shadow: any, address: any, ytBuildingId: any) => {
    getOwnerList(str, accountType, shadow, dateValue, address, ytBuildingId).then((res: any) => {
      setArrList(res?.data)
      setStackBoo(false)
    })
  }

  const exportObj = (res: any) => {
    let arrObj: any = {}
    arrObj = {
      '!cols': [],
      '!fullref': `A1:G${(res?.length || 0) + 1}`,
      '!ref': `A1:G${(res?.length || 0) + 1}`,
      '!rows': [],
      A1: { t: 's', v: '小区' },
      B1: { t: 's', v: '楼号' },
      C1: { t: 's', v: '门牌号' },
      D1: { t: 's', v: '收当年' },
      E1: { t: 's', v: '当年欠' },
      F1: { t: 's', v: '收历年' },
      G1: { t: 's', v: '历年欠' },
    }
    res?.forEach((item: any, i: number) => {
      arrObj[`A${i + 2}`] = { t: 's', v: `${item.houseAddress1}${item.houseAddress2}` || '' }
      arrObj[`B${i + 2}`] = { t: 's', v: item.houseAddress3 || '' }
      arrObj[`C${i + 2}`] = { t: 's', v: item.houseNum || '' }
      arrObj[`D${i + 2}`] = {
        t: 'n',
        v: (Number(item.currentYearIncome) || 0) + (Number(item.prepayCurrentYear) || 0),
      }
      arrObj[`E${i + 2}`] = { t: 'n', v: Number(item.currentYearOverdue) || 0 }
      arrObj[`F${i + 2}`] = {
        t: 'n',
        v: (Number(item.lastYearOverdueIncome) || 0) + (Number(item.overYearsOverdueIncome) || 0),
      }
      arrObj[`G${i + 2}`] = { t: 'n', v: (Number(item.lastYearOverdue) || 0) + (Number(item.overYearsOverdue) || 0) }
    })
    setExportBoo(true)
    setArrExportList(arrObj)
  }

  const getExportNewArr = (str: any, accountType: any, shadow: any) => {
    getOwnerList(str, accountType, shadow, dateValue).then((res: any) => {
      exportObj(
        res?.data?.filter(
          (res: any) => res.lastYearOverdue + res.overYearsOverdue !== 0 || res.currentYearOverdue !== 0,
        ),
      )
    })
  }

  const setList = (obj: any) => {
    getCommunityList(
      Number(moment.utc(new Date()).format('MM')) < 4
        ? moment.utc(new Date()).subtract(1, 'year').format('YYYY')
        : moment.utc(new Date()).format('YYYY'),
      12,
      obj?.id,
      typeObj[obj?.typeValue],
    ).then((res: any) => {
      const overdueObj = res?.overdue
      const metricsObj = res?.metrics
      const incomeSObj = res?.incomes
      const newArr: any = []
      const allArr: any = []
      overdueObj?.map((item: any) => {
        const childObj: any = metricsObj[item.projectId]?.filter(
          (items: any) => items.assessType === typeObj[obj?.typeValue],
        )[0]
        newArr.push({
          year: item.year,
          projectId: item.projectId,
          projectName: item.projectName,
          chargeName: item.chargeName,
          preYearAlloc: childObj?.preYearAlloc,
          currentYearAccountsReceivable: item.currentYearAccountsReceivable,
          currentYearIncomeMetric: childObj?.currentYearMetric,
          overYearsIncomeMetric: childObj?.overYearMetric,
        })
      })
      newArr?.map((item: any) => {
        let historicalArrears: any = 0
        let preYearArrears: any = 0
        let currentYearArrears: any = 0
        let currentMonthActual: any = 0
        let currentYearPrepaidActual: any = 0
        let preYearAllocCurrentMonth: any = 0
        incomeSObj?.map((items: any) => {
          if (item.projectId === items.projectId && items?.incomeName !== '临时停车费') {
            historicalArrears += items.historicalArrears
            preYearArrears += items.preYearArrears
            currentYearArrears += items.currentYearArrears
            currentMonthActual += items.currentMonthActual
            currentYearPrepaidActual += items.currentYearPrepaidActual
            preYearAllocCurrentMonth += items.preYearAllocCurrentMonth
          }
        })
        allArr.push({
          ...item,
          historicalArrears,
          preYearArrears,
          currentYearArrears,
          currentMonthActual,
          currentYearPrepaidActual,
          // preYearAllocCurrentMonth,
          preYearAllocCurrentMonth:
            item?.preYearAlloc !== 0 && `${item?.preYearAlloc}` !== 'null'
              ? item?.preYearAlloc
              : preYearAllocCurrentMonth,
        })
      })
      const listObj: any = allArr?.filter((item: any) => item.projectId === obj?.id)[0]
      setCommunityObj(listObj || '')
      setLoadingBoo(false)
    })
  }

  const getCommunityData = (obj: any) => {
    setList(obj)
  }

  useEffect(() => {
    if (propertyCommunity?.typeValue) {
      setStackBoo(false)
      setAddressValue('')
      setTypographyValue(arrParkingLotList?.filter((res: any) => res.id === propertyCommunity?.id)[0]?.name || '')
      setTypeValue(typeObj[propertyCommunity?.typeValue])
    }
  }, [propertyCommunity?.typeValue, arrParkingLotList])

  useEffect(() => {
    if (propertyCommunity?.id) {
      setSelectValue(propertyCommunity?.id)
      setTypographyValue(arrParkingLotList?.filter((res: any) => res.id === propertyCommunity?.id)[0]?.name || '')
    }
  }, [propertyCommunity?.id])

  useEffect(() => {
    if (
      propertyCommunity?.typeValue &&
      propertyCommunity?.id &&
      ((Number(propertyCommunity?.typeValue) > 4 && Number(propertyCommunity?.typeValue) < 9) ||
        (Number(propertyCommunity?.typeValue) > 16 && Number(propertyCommunity?.typeValue) < 21))
    ) {
      getCommunityData({ ...propertyCommunity })
      setArrList([])
      setStackBoo(true)
      setTitleBoo(true)
      setLoadingBoo(true)
      getData(propertyCommunity?.id, typeObj[propertyCommunity?.typeValue])
      setExportBoo(false)
      getExportNewArr(propertyCommunity?.id, typeObj[propertyCommunity?.typeValue], shadowBoo)
    }
  }, [propertyCommunity?.typeValue, propertyCommunity?.id])

  useEffect(() => {
    getParkingLotArr()
  }, [])

  useEffect(() => {
    if (timer) {
      clearInterval(timer)
    }
    if (!loadingBoo) {
      timer = setInterval(() => {
        setList({ ...propertyCommunity })
      }, 10000)
    }

    return () => {
      clearInterval(timer)
    }
  }, [loadingBoo, propertyCommunity?.typeValue, propertyCommunity?.id])

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {communityHeaderArr?.map((item: any) => {
                return (
                  <StyledTableCell style={{ width: item.width }} key={item.key} align="left">
                    {item.name}
                  </StyledTableCell>
                )
              })}
              <StyledTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {loadingBoo ? (
              <StyledTableRow>
                <StyledTableCell style={{ border: 0 }} colSpan={communityHeaderArr?.length}>
                  <div className={classes.loadingBox}>
                    <LoadingBox />
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              <>
                <StyledTableRow>
                  <StyledTableCell align="left">当年</StyledTableCell>
                  <StyledTableCell align="left">
                    {getNewNumber(communityObj?.currentYearIncomeMetric, 2) || 0}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {getNewNumber(
                      communityObj?.currentYearArrears +
                        communityObj?.currentMonthActual +
                        communityObj?.currentYearPrepaidActual +
                        communityObj?.preYearAllocCurrentMonth,
                      2,
                    ) || 0}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {`${communityObj?.currentYearIncomeMetric}` !== '0'
                      ? `${getNewNumberPercentage(
                          (communityObj?.currentYearArrears +
                            communityObj?.currentMonthActual +
                            communityObj?.currentYearPrepaidActual +
                            communityObj?.preYearAllocCurrentMonth) /
                            communityObj?.currentYearIncomeMetric,
                          2,
                        )}`
                      : `0%`}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left">旧欠</StyledTableCell>
                  <StyledTableCell align="left">
                    {getNewNumber(communityObj?.overYearsIncomeMetric, 2) || 0}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {getNewNumber(communityObj.preYearArrears + communityObj.historicalArrears, 2) || 0}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {`${communityObj?.overYearsIncomeMetric}` !== '0'
                      ? `${getNewNumberPercentage(
                          (communityObj.preYearArrears + communityObj.historicalArrears) /
                            communityObj?.overYearsIncomeMetric,
                          2,
                        )}`
                      : `0%`}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left">总计</StyledTableCell>
                  <StyledTableCell align="left">
                    {getNewNumber(communityObj?.currentYearIncomeMetric + communityObj?.overYearsIncomeMetric, 2) || 0}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {getNewNumber(
                      communityObj?.currentYearArrears +
                        communityObj?.currentMonthActual +
                        communityObj?.currentYearPrepaidActual +
                        communityObj.preYearArrears +
                        communityObj.historicalArrears +
                        communityObj?.preYearAllocCurrentMonth,
                      2,
                    ) || 0}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {`${communityObj?.overYearsIncomeMetric + communityObj?.currentYearIncomeMetric}` !== '0'
                      ? `${getNewNumberPercentage(
                          (communityObj?.currentYearArrears +
                            communityObj?.currentMonthActual +
                            communityObj?.currentYearPrepaidActual +
                            communityObj.preYearArrears +
                            communityObj.historicalArrears +
                            communityObj?.preYearAllocCurrentMonth) /
                            (communityObj?.overYearsIncomeMetric + communityObj?.currentYearIncomeMetric),
                          2,
                        )}`
                      : `0%`}
                  </StyledTableCell>
                </StyledTableRow>
              </>
            )}
          </TableBody>
        </Table>
      </div>
      <div className={classes.title}>
        <div className={classes.titleLeftBox}>
          {divWidth > 1280 && !urlName?.includes('management') && (
            <div className={classes.selectBigBox}>
              <div className={classes.titleText}>小区：</div>
              <div className={classes.selectBox}>
                <FormControl className={classes.iptModal} fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    value={arrParkingLotList?.length > 0 ? selectValue : ''}
                    onChange={(event: any) => {
                      setYtBuildingIdValue('')
                      setSelectValue(event.target.value)
                    }}
                  >
                    {arrParkingLotList?.map((item: any, i: number) => {
                      return (
                        <MenuItem key={i} value={item.id}>
                          {`${item.name}-${item.id}`}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
          )}
          <div className={classes.selectBigBox}>
            <div className={classes.titleText}>地址：</div>
            <div className={classes.selectBox}>
              <TextField
                className={classes.iptModal}
                value={addressValue}
                onChange={(event: any) => {
                  setAddressValue(event.target.value)
                }}
                id="outlined-basic"
                variant="outlined"
              />
            </div>
          </div>
          <div className={classes.selectBigBox}>
            <div className={classes.titleText}>时间： </div>
            <div className={classes.dateBox}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateTimePicker']}>
                  <DateTimePicker
                    ampm={false}
                    format="YYYY"
                    minDateTime={dayjs('2024')}
                    maxDateTime={dayjs(
                      moment.utc(new Date(new Date().getTime() + 3 * 365 * 24 * 60 * 60 * 1000)).format('YYYY'),
                    )}
                    views={['year']}
                    value={dayjs(dateValue)}
                    onChange={(e: any) => {
                      setDateValue(dayjs(e).format('YYYY'))
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>
          {divWidth <= 1280 && (
            <div className={classes.selectBigBox}>
              <Button
                style={{ marginRight: 10 }}
                onClick={() => {
                  setStackBoo(true)
                  if (propertyCommunity?.id !== selectValue && addressValue === '') {
                    setTitleBoo(true)
                  } else {
                    setTitleBoo(
                      (typographyValue === arrParkingLotList?.filter((res: any) => res.id === selectValue)[0]?.name ||
                        typographyValue === '') &&
                        addressValue === '',
                    )
                  }
                  navigate(`?id=${selectValue}&typeValue=${propertyCommunity?.typeValue}`)
                  if (addressValue !== '') {
                    getNewArr(selectValue, typeValue, shadowBoo, addressValue, ytBuildingIdValue)
                  } else {
                    if (ytBuildingIdValue !== '') {
                      getNewArr(selectValue, typeValue, shadowBoo, addressValue, ytBuildingIdValue)
                    } else {
                      getData(selectValue, typeValue)
                    }
                  }
                }}
                variant="contained"
                className={classes.btn}
              >
                查询
              </Button>
              {exportBoo && (
                <Button
                  onClick={() => {
                    exportExcel(
                      arrExportList,
                      `${typographyValue}-${typeValue}欠款-${moment.utc(new Date()).format('YYYY-MM-DD')}.xlsx`,
                    )
                  }}
                  variant="contained"
                  className={classes.btn}
                >
                  导出
                </Button>
              )}
            </div>
          )}
        </div>
        {divWidth > 1280 && (
          <div>
            <Button
              onClick={() => {
                setStackBoo(true)
                if (propertyCommunity?.id !== selectValue && addressValue === '') {
                  setTitleBoo(true)
                } else {
                  setTitleBoo(
                    (typographyValue === arrParkingLotList?.filter((res: any) => res.id === selectValue)[0]?.name ||
                      typographyValue === '') &&
                      addressValue === '',
                  )
                }
                navigate(`?id=${selectValue}&typeValue=${propertyCommunity?.typeValue}`)
                if (addressValue !== '') {
                  getNewArr(selectValue, typeValue, shadowBoo, addressValue, ytBuildingIdValue)
                } else {
                  if (ytBuildingIdValue !== '') {
                    getNewArr(selectValue, typeValue, shadowBoo, addressValue, ytBuildingIdValue)
                  } else {
                    getData(selectValue, typeValue)
                  }
                }
              }}
              variant="contained"
              className={classes.btn}
            >
              查询
            </Button>
            {divWidth > 1280 && (
              <Button
                style={{ marginLeft: 20 }}
                onClick={() => {
                  navigate(
                    `${urlName}${propertyCommunity?.typeValue}?id=${selectValue}&typeValue=${propertyCommunity?.typeValue}`,
                  )
                }}
                variant="contained"
                className={classes.btn}
              >
                分享
              </Button>
            )}
            {exportBoo && (
              <Button
                style={{ marginLeft: 20 }}
                onClick={() => {
                  exportExcel(
                    arrExportList,
                    `${typographyValue}-${typeValue}欠款-${moment.utc(new Date()).format('YYYY-MM-DD')}.xlsx`,
                  )
                }}
                variant="contained"
                className={classes.btn}
              >
                导出
              </Button>
            )}
          </div>
        )}
      </div>
      <div className={classes.breadcrumbBox} role="presentation">
        {typographyValue === arrParkingLotList?.filter((res: any) => res.id === propertyCommunity?.id)[0]?.name ||
        typographyValue === '' ? (
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="text.primary">{typographyValue}</Typography>
          </Breadcrumbs>
        ) : (
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              onClick={() => {
                setStackBoo(true)
                if (addressValue) {
                  titleBoo && setTitleBoo(false)
                  getNewArr(selectValue, typeValue, shadowBoo, addressValue, '')
                } else {
                  !titleBoo && setTitleBoo(true)
                  getData(selectValue, typeValue)
                }
                setYtBuildingIdValue('')
                setTypographyValue(arrParkingLotList?.filter((res: any) => res.id === propertyCommunity?.id)[0]?.name)
              }}
            >
              {arrParkingLotList?.filter((res: any) => res.id === propertyCommunity?.id)[0]?.name}
            </Link>
            <Typography color="text.primary">{typographyValue}</Typography>
          </Breadcrumbs>
        )}
        <FormGroup>
          <FormControlLabel
            className={classes.checkBox}
            control={
              <Checkbox
                checked={checked}
                onChange={(e: any) => {
                  setChecked(e.target.checked)
                }}
                color="default"
              />
            }
            label="只看欠费"
          />
        </FormGroup>
      </div>
      <div className={classes.tableBox}>
        <Table stickyHeader aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {(divWidth > 1280 ? headerArr : iphoneHeaderArr)?.map((item: any) => {
                return (
                  <StyledTableCell style={{ width: item.width }} key={item.key} align="left">
                    {item.name}
                  </StyledTableCell>
                )
              })}
              <StyledTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {stackBoo ? (
              <StyledTableRow>
                <StyledTableCell
                  style={{ border: 0 }}
                  colSpan={(divWidth > 1280 ? headerArr : iphoneHeaderArr)?.length}
                >
                  <div className={classes.loadingBox}>
                    <LoadingBox />
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              (checked
                ? arrList?.filter(
                    (res: any) => res.lastYearOverdue + res.overYearsOverdue !== 0 || res.currentYearOverdue !== 0,
                  )
                : arrList
              )?.map((item: any, i: number) => {
                return divWidth > 1280 ? (
                  <StyledTableRow key={i}>
                    <StyledTableCell align="left">
                      {titleBoo ? (
                        <div className={classes.thBox}>
                          <span
                            onClick={() => {
                              if (addressValue === '') {
                                if (
                                  typographyValue ===
                                    arrParkingLotList?.filter((res: any) => res.id === propertyCommunity?.id)[0]
                                      ?.name ||
                                  typographyValue === ''
                                ) {
                                  setTitleBoo(false)
                                  setStackBoo(true)
                                  setYtBuildingIdValue(item.buildingId)
                                  getNewArr(selectValue, typeValue, shadowBoo, addressValue, item.buildingId)
                                  setTypographyValue(`${item?.houseAddress2}${item.houseAddress3}`)
                                }
                              }
                            }}
                            className={classes.thBoxHover}
                          >{`${item.houseAddress2}${item.houseAddress3}`}</span>
                        </div>
                      ) : (
                        <div className={classes.thBox}>{`${item.houseAddress3}${item.houseNum}`}</div>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div className={classes.thBox}>{getNewNumber(item.currentYearAccountsReceivable, 2) || 0}</div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div className={classes.thBox}>{getNewNumber(item.currentYearPrepayment, 2) || 0}</div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div className={classes.thBox}>{getNewNumber(item.currentYearIncome, 2) || 0}</div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div className={classes.thBox}>{getNewNumber(item.lastYearOverdueIncome, 2) || 0}</div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div className={classes.thBox}>{getNewNumber(item.overYearsOverdueIncome, 2) || 0}</div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div className={classes.thBox}>{getNewNumber(item.currentYearOverdue, 2) || 0}</div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div className={classes.thBox}>{getNewNumber(item.lastYearOverdue, 2) || 0}</div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div className={classes.thBox}>{getNewNumber(item.overYearsOverdue, 2) || 0}</div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div className={classes.thBox}>{getNewNumber(item.prepayCurrentYear, 2) || 0}</div>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  <StyledTableRow key={i}>
                    <StyledTableCell align="left">
                      {titleBoo ? (
                        <div style={{ width: '21.4vw' }} className={classes.thBox}>
                          <span
                            onClick={() => {
                              if (addressValue === '') {
                                if (
                                  typographyValue ===
                                    arrParkingLotList?.filter((res: any) => res.id === propertyCommunity?.id)[0]
                                      ?.name ||
                                  typographyValue === ''
                                ) {
                                  setTitleBoo(false)
                                  setStackBoo(true)
                                  setYtBuildingIdValue(item.buildingId)
                                  getNewArr(selectValue, typeValue, shadowBoo, addressValue, item.buildingId)
                                  setTypographyValue(`${item?.houseAddress2}${item.houseAddress3}`)
                                }
                              }
                            }}
                            className={classes.thBoxHover}
                          >
                            {`${item.houseAddress2}${item.houseAddress3}`.substring(
                              `${item.houseAddress2}${item.houseAddress3}`?.length - (selectValue === '101' ? 6 : 8),
                              `${item.houseAddress2}${item.houseAddress3}`?.length,
                            )}
                          </span>
                        </div>
                      ) : (
                        <div style={{ width: '21.4vw' }} className={classes.thBox}>
                          {`${item.houseAddress3}${item.houseNum}`}
                        </div>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div className={classes.thBox}>
                        {getNewNumber(item.currentYearIncome + item.prepayCurrentYear, 2) || 0}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div className={classes.thBox}>{getNewNumber(item.currentYearOverdue, 2) || 0}</div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div className={classes.thBox}>
                        {getNewNumber(item.lastYearOverdueIncome + item.overYearsOverdueIncome, 2) || 0}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div className={classes.thBox}>
                        {getNewNumber(item.lastYearOverdue + item.overYearsOverdue, 2) || 0}
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                )
              })
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default PropertyDetails
