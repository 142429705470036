import React, { useEffect, useState } from 'react'
import { makeStyles, withStyles } from 'tss-react/mui'
import { getBills } from '@/api'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import LoadingBox from '@/components/baseMap/loadingBox'
import moment from 'moment'

const StyledTableCell = withStyles(TableCell, (theme) => ({
  head: {
    color: theme.palette.common.black,
    padding: '1vh 0',
    border: 0,
    fontSize: '1.8vh',
    fontWeight: 600,
    lineHeight: 1.15,
    '@media (max-width: 1280px)': {
      fontSize: '1.5vh',
      padding: '1.3vh 0',
    },
  },
  body: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    padding: '0.5vh 0',
    fontSize: '1.6vh',
    lineHeight: 1.15,
    borderBottom: '1px solid rgba(238,238,238,0.3)',
    '@media (max-width: 1280px)': {
      fontSize: '1.4vh',
    },
  },
}))

const StyledTableRow = withStyles(TableRow, () => ({
  root: {
    // borderRadius: 5,
    // backgroundImage: `url(${NavigationImg})`,
    // backgroundColor: '#214162',
  },
}))

const useStyles = makeStyles()(() => ({
  tableBox: {
    flex: 1,
    width: '100%',
    overflowY: 'auto',
    position: 'relative',
  },
  loadingBox: {
    position: 'absolute',
    top: 30,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
  },
}))

interface DailyChargesTableProps {
  idNum: any
  childNum: any
}

const DailyChargesTable = (props: DailyChargesTableProps) => {
  const { idNum, childNum } = props
  const { classes } = useStyles()
  const [dataArr, setDataArr] = useState<any>([])
  const [stackBoo, setStackBoo] = useState<any>(false)
  const [contentBoo, setContentBoo] = useState<any>(false)

  const headerArr = [
    {
      key: 0,
      name: '序号',
      width: '6%',
    },
    {
      key: 1,
      name: '项目名称',
      width: '8.4%',
    },
    {
      key: 2,
      name: '账单日期',
      width: '8.4%',
    },
    {
      key: 3,
      name: '账单金额',
      width: '8.4%',
    },
    {
      key: 4,
      name: '收款日期',
      width: '8.4%',
    },
    {
      key: 5,
      name: '收款方式',
      width: '8.4%',
    },
    {
      key: 6,
      name: '收款人',
      width: '7%',
    },
    {
      key: 7,
      name: '票据号码',
      width: '25%',
    },
    {
      key: 8,
      name: '备注',
      width: '20%',
    },
  ]

  useEffect(() => {
    if (idNum) {
      setStackBoo(true)
      setContentBoo(true)

      getBills(idNum, 0, 999999)
        .then((res: any) => {
          setDataArr(res.data)
          setStackBoo(false)
        })
        .catch(console.error)
    } else {
      setContentBoo(false)
      setDataArr([])
    }
  }, [idNum])

  useEffect(() => {
    if (childNum) {
      setContentBoo(false)
      setDataArr([])
    }
  }, [childNum])

  return (
    <div id="tableBox" className={classes.tableBox}>
      {contentBoo && (
        <Table stickyHeader aria-label="customized table">
          <TableHead>
            <StyledTableRow>
              {headerArr?.map((item: any, i: any) => {
                return (
                  <StyledTableCell
                    style={{ width: item.width }}
                    key={item.key}
                    align={i === 7 || i === 8 ? 'left' : 'center'}
                  >
                    {item.name}
                  </StyledTableCell>
                )
              })}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {stackBoo ? (
              <TableRow>
                <StyledTableCell style={{ border: 0 }} colSpan={headerArr?.length} align="center">
                  <div className={classes.loadingBox}>
                    <LoadingBox />
                  </div>
                </StyledTableCell>
              </TableRow>
            ) : (
              dataArr?.map((item: any, i: number) => {
                let backgroundStr
                if (moment(item?.billDate) < moment(new Date())) {
                  if (item?.paymentDate) {
                    backgroundStr = '#ffe0c0'
                  } else {
                    backgroundStr = '#ffc0c0'
                  }
                } else {
                  if (item?.paymentDate) {
                    backgroundStr = '#c0ffff'
                  } else {
                    backgroundStr = '#ffffff'
                  }
                }
                return (
                  <TableRow style={{ background: backgroundStr }} key={i}>
                    <StyledTableCell align="center" style={{ overflowWrap: 'anywhere' }}>
                      {i + 1}
                    </StyledTableCell>
                    <StyledTableCell align="center" style={{ overflowWrap: 'anywhere' }}>
                      {item?.chargeName}
                    </StyledTableCell>
                    <StyledTableCell align="center" style={{ overflowWrap: 'anywhere' }}>
                      {moment(item?.billDate).format('YYYY-MM')}
                    </StyledTableCell>
                    <StyledTableCell align="center" style={{ overflowWrap: 'anywhere' }}>
                      {item?.money ? item?.money?.toFixed(2) : 0}
                    </StyledTableCell>
                    <StyledTableCell align="center" style={{ overflowWrap: 'anywhere' }}>
                      {item?.paymentDate}
                    </StyledTableCell>
                    <StyledTableCell align="center" style={{ overflowWrap: 'anywhere' }}>
                      {item?.paymentMethodType}
                    </StyledTableCell>
                    <StyledTableCell align="center" style={{ overflowWrap: 'anywhere' }}>
                      {item?.payee}
                    </StyledTableCell>
                    <StyledTableCell align="left" style={{ overflowWrap: 'anywhere' }}>
                      {item?.invoiceNum}
                    </StyledTableCell>
                    <StyledTableCell align="left" style={{ overflowWrap: 'anywhere' }}>
                      <div />
                    </StyledTableCell>
                  </TableRow>
                )
              })
            )}
          </TableBody>
        </Table>
      )}
    </div>
  )
}

export default DailyChargesTable
