import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { FormControl, MenuItem, Select } from '@mui/material'
import { getBuildList, getHouseList, getOwnerDetails, getProjectList, getQueryParamsObj } from '@/api'
import BuildContentBox from '@/components/adminBox/dailyCharges/buildContentBox'
import DailyChargesTable from '@/components/adminBox/dailyCharges/dailyChargesTable'
import OwnerBox from '@/components/adminBox/dailyCharges/ownerBox'

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    height: '100%',
    padding: 15,
  },
  title: {
    padding: '10px 0',
    width: '100%',
    height: '7%',
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 1280px)': {
      flexDirection: 'column',
    },
  },
  tableBox: {
    width: '100%',
    height: '93%',
    color: '#000',
    position: 'relative',
    '@media (max-width: 1280px)': {
      overflow: 'auto',
    },
  },
  titleLeftBox: {
    display: 'flex',
    color: '#000',
    height: '100%',
    width: '60%',
    '@media (max-width: 1280px)': {
      flexDirection: 'column',
      height: '100%',
      width: '100%',
    },
  },
  selectBigBox: {
    width: '26%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
    '@media (max-width: 1280px)': {
      width: '100%',
      height: '100%',
      marginRight: 0,
      marginBottom: 10,
    },
  },
  titleText: {
    width: '8vh',
    fontSize: '2.5vh',
    fontWeight: 600,
  },
  selectBox: {
    width: '65%',
    height: '100%',
    '@media (max-width: 1280px)': {
      width: '22vh',
    },
  },
  iptModal: {
    height: '100%',
    '&>div': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '2vh',
      background: 'transparent',
      padding: '6px 10px',
      height: '100%',
      '&>input': {
        padding: 0,
        height: '100%',
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
      '&>div': {
        padding: 0,
      },
    },
    '&>input': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '2vh',
      background: 'transparent',
      padding: '6px 10px',
      '&>input': {
        padding: 0,
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
    },
    '&:hover::before': {
      borderBottom: '2px solid #000 !important',
    },
  },
  leftBox: {
    width: '28%',
    borderRadius: 5,
    boxShadow: '-1px 1px 15px -4px #000',
    padding: 5,
    height: '97%',
    overflow: 'auto',
    '@media (max-width: 1280px)': {
      width: '23%',
    },
  },
  rightBox: {
    width: '68%',
    borderRadius: 5,
    boxShadow: '-1px 1px 15px -4px #000',
    padding: 5,
    height: '97%',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 1280px)': {
      width: '72%',
    },
  },
  rightBoxTitle: {
    padding: '0.5vh 0',
    display: 'flex',
  },
  titleColorBox: {
    padding: 2,
    marginRight: 15,
    border: '1px solid #000',
  },
  tableSmallBox: {
    '@media (min-width: 1281px)': {
      width: '100%',
      height: '100%',
      overflow: 'auto',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    '@media (max-width: 1280px)': {
      width: 1300,
      height: '100%',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
  },
}))

const DailyChargesBox = () => {
  const { classes } = useStyles()
  const [selectValue, setSelectValue] = useState('')
  const [arrParkingLotList, setArrParkingLotList] = useState<any>([])
  const [buildArr, setBuildArr] = useState<any>([])
  const [idNum, setIdNum] = useState<any>('')
  const [idStr, setIdStr] = useState<any>('')
  const [idObj, setIdObj] = useState<any>('')
  const [childNum, setChildNum] = useState<any>('')
  const dateObj = getQueryParamsObj(window.location?.search)

  const getParkingLotArr = () => {
    getProjectList()
      .then((res: any) => {
        setArrParkingLotList(res)
      })
      .catch(console.error)
  }

  const getIdNum = (num: any) => {
    setIdNum(num)
  }

  const getIdStr = (str: any) => {
    setIdStr(str)
  }

  const getIdObj = (obj: any) => {
    setIdObj(obj)
  }

  const getBuild = (id: any) => {
    getBuildList(id)
      .then((res: any) => {
        if (res) {
          const newArr: any = []
          res?.map((item: any) => {
            newArr.push({ ...item, open: false })
          })
          setBuildArr(newArr)
        }
      })
      .catch(console.error)
  }

  const getOwnerBuild = (obj: any) => {
    getHouseList(obj?.projectId, obj?.buildingId)
      .then((res: any) => {
        if (res) {
          setIdObj(res?.data.filter((item: any) => item.ownerId === obj?.id)[0])
        }
      })
      .catch(console.error)
    getBuildList(obj?.projectId)
      .then((res: any) => {
        if (res) {
          const newArr: any = []
          res?.map((item: any) => {
            newArr.push({ ...item, open: false })
            if (item.id === obj.buildingId) {
              setIdNum(obj.id)
              setIdStr(`${item.address1}${item.address2}${item.address3}`)
            }
          })
          setBuildArr(newArr)
        }
      })
      .catch(console.error)
  }

  const getOwnerObj = (id: any) => {
    getOwnerDetails(id)
      .then((res: any) => {
        setSelectValue(res.projectId)
        getOwnerBuild(res)
      })
      .catch(console.error)
  }

  useEffect(() => {
    getParkingLotArr()
    if (dateObj?.ownerId) {
      getOwnerObj(dateObj?.ownerId)
    }
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <div className={classes.titleLeftBox}>
          <div className={classes.selectBigBox}>
            <div className={classes.titleText}>小区：</div>
            <div className={classes.selectBox}>
              <FormControl className={classes.iptModal} fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  value={arrParkingLotList?.length > 0 ? selectValue : ''}
                  onChange={(event: any) => {
                    getBuild(event.target.value)
                    setIdNum('')
                    setIdStr('')
                    setIdObj('')
                    setChildNum(Math.random())
                    setSelectValue(event.target.value)
                  }}
                >
                  {arrParkingLotList?.map((item: any, i: number) => {
                    return (
                      <MenuItem key={i} value={item.id}>
                        {`${item.name}-${item.id}`}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.tableBox}>
        <div className={classes.tableSmallBox}>
          <div className={classes.leftBox}>
            {buildArr?.map((item: any, i: any) => {
              return (
                <BuildContentBox
                  key={i}
                  object={item}
                  getIdNum={getIdNum}
                  idNum={idNum}
                  getIdStr={getIdStr}
                  getIdObj={getIdObj}
                />
              )
            })}
          </div>
          <div className={classes.rightBox}>
            {idObj !== '' && <OwnerBox idObj={idObj} idStr={idStr} idNum={idNum} childNum={childNum} />}
            {idNum !== '' && (
              <div className={classes.rightBoxTitle}>
                <div className={classes.titleColorBox} style={{ background: '#ffe0c0' }}>
                  到期已收
                </div>
                <div className={classes.titleColorBox} style={{ background: '#ffc0c0' }}>
                  到期未收
                </div>
                <div className={classes.titleColorBox} style={{ background: '#c0ffff' }}>
                  未到期已收
                </div>
                <div className={classes.titleColorBox}>未到期未收</div>
              </div>
            )}
            <DailyChargesTable idNum={idNum} childNum={childNum} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DailyChargesBox
