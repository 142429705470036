import React, { useEffect, useState } from 'react'
import { makeStyles, withStyles } from 'tss-react/mui'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import Pagination from '@mui/material/Pagination'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import Modal from '@mui/material/Modal'
import moment from 'moment/moment'
import { getBills } from '@/api'

const StyledTableCell = withStyles(TableCell, (theme) => ({
  head: {
    color: theme.palette.common.black,
    padding: '1.5vh 0',
    border: 0,
    fontSize: '2.2vh',
    fontWeight: 600,
    lineHeight: 1.15,
    '@media (max-width: 1280px)': {
      fontSize: '1.5vh',
      padding: '1.3vh 0',
    },
  },
  body: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    padding: '1vh 0',
    fontSize: '1.8vh',
    lineHeight: 1.15,
    borderBottom: '1px solid rgba(238,238,238,0.3)',
    '@media (max-width: 1280px)': {
      fontSize: '1.4vh',
    },
  },
}))

const StyledTableRow = withStyles(TableRow, () => ({
  root: {
    // backgroundColor: '#31353E',
  },
}))

const useStyles = makeStyles()(() => ({
  thBox: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    lineHeight: '34px',
    '@media (max-width: 1280px)': {
      lineHeight: '18px',
    },
  },
  turnPage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  paginationBox: {
    '&>ul>li>.MuiPaginationItem-page.Mui-selected': {
      backgroundColor: '#c4c1c1',
      color: '#333',
    },
    '&>ul>li>button': {
      color: '#999',
      fontSize: 40,
      height: 48,
      minWidth: 48,
      padding: 0,
      '@media (max-width: 1280px)': {
        fontSize: 18,
        height: 26,
        minWidth: 26,
      },
    },
    '&>ul>li>button>svg': {
      fontSize: 45,
      padding: 0,
      '@media (max-width: 1280px)': {
        fontSize: 35,
      },
    },
    '&>ul>li>div': {
      color: '#999',
      fontSize: 40,
      height: 48,
      minWidth: 48,
      padding: 0,
      '@media (max-width: 1280px)': {
        fontSize: 26,
        height: 30,
        minWidth: 30,
      },
    },
  },
  tableBox: {
    height: 'calc( 100% - 48px )',
    width: '100%',
    overflowY: 'auto',
    '@media (max-width: 1280px)': {
      height: 'calc( 100% - 30px)',
    },
  },
  modalBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalSmallBox: {
    width: '50%',
    height: '85%',
    background: '#eee',
    padding: 10,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 1280px)': {
      height: '70%',
      width: '90%',
    },
  },
}))

interface CarListBoxProps {
  divWidth: any
  carIdStr: any
  open: any
  handleClose: any
  ownerIdStr: any
  setNewOwnerIdStr: () => void
}

const ModalBox = (props: CarListBoxProps) => {
  const { divWidth, open, handleClose, ownerIdStr, setNewOwnerIdStr, carIdStr } = props
  const { classes } = useStyles()
  const [page, setPage] = useState<any>(1)
  const [arrList, setArrList] = useState<any>('')

  const headerArr = [
    {
      key: 0,
      name: '账单年月',
      width: '25%',
    },
    {
      key: 1,
      name: '收款日期',
      width: '25%',
    },
    {
      key: 2,
      name: '收款方式',
      width: '25%',
    },
    {
      key: 3,
      name: '金额',
      width: '25%',
    },
  ]

  const getList = () => {
    const rootBox = document.getElementById('root')
    if (rootBox?.clientHeight) {
      divWidth <= 1280
        ? getBills(
            ownerIdStr,

            page - 1,
            Math.trunc((Math.round(rootBox?.clientHeight * 0.7) - 20 - 30) / 36) - 1,
            '地面停车费',
            carIdStr,
          )
            .then((res: any) => {
              setArrList(res)
            })
            .catch(console.error)
        : getBills(
            ownerIdStr,
            page - 1,
            Math.trunc((Math.round(rootBox?.clientHeight * 0.85) - 20 - 48) / 54) - 1,
            '地面停车费',
            carIdStr,
          )
            .then((res: any) => {
              setArrList(res)
            })
            .catch(console.error)
    }
  }

  useEffect(() => {
    if (ownerIdStr !== '' && carIdStr !== '' && open) {
      getList()
    }
  }, [page, open])

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => {
          handleClose(false)
          setNewOwnerIdStr()
          setPage(1)
          setArrList('')
        }}
        closeAfterTransition
        className={classes.modalBox}
      >
        <div className={classes.modalSmallBox}>
          <div id="modalTableBox" className={classes.tableBox}>
            <Table stickyHeader aria-label="customized table">
              <TableHead>
                <TableRow>
                  {headerArr?.map((item: any) => {
                    return (
                      <StyledTableCell style={{ width: item.width }} key={item.key} align="left">
                        {item.name}
                      </StyledTableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {arrList?.data?.map((item: any, i: number) => {
                  return (
                    <StyledTableRow key={i}>
                      <StyledTableCell align="left">
                        <div className={classes.thBox}>
                          {item?.billDate !== null ? moment.utc(item?.billDate).format('YYYY-MM') : ''}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <div className={classes.thBox}>{item?.paymentDate}</div>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <div className={classes.thBox}>{item?.paymentMethodType}</div>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <div className={classes.thBox}>{item?.money}</div>
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
          </div>
          <div className={classes.turnPage}>
            <Pagination
              className={classes.paginationBox}
              boundaryCount={1}
              page={page}
              onChange={(_e, num: number) => {
                setPage(num)
              }}
              count={arrList?.pages || 0}
              shape="rounded"
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalBox
